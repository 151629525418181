// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudIaasStorageProject_Details_RequiredProductsScss from "./UserCloudIaasStorageProject_Details_RequiredProducts.scss";

var css = UserCloudIaasStorageProject_Details_RequiredProductsScss;

function UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: props.label,
                      className: Cx.cx([
                            css.productServiceLabel,
                            css.withColon
                          ])
                    }),
                JsxRuntime.jsx("p", {
                      children: props.value,
                      className: css.productServiceValue
                    })
              ],
              className: css.productsServiceWrapper
            });
}

var make = UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription;

export {
  css ,
  make ,
}
/* css Not a pure module */
