// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as CloudIaasStorage from "./CloudIaasStorage.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              accessTypes: field.required("accessTypes", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              additionalRequirements: field.required("additionalRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              cloudStorage: field.required("cloudStorage", CloudIaasStorage.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              features: field.required("features", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
