// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserCloudIaasStorageProject_Details_RequiredProductsScss from "./UserCloudIaasStorageProject_Details_RequiredProducts.scss";
import * as UserCloudIaasStorageProject_Details_RequiredProducts_ServerInstance from "./UserCloudIaasStorageProject_Details_RequiredProducts_ServerInstance.res.js";

var css = UserCloudIaasStorageProject_Details_RequiredProductsScss;

function panelHeading(configuration) {
  var diskType = configuration.cloudStorage.diskType;
  var connectivity = configuration.cloudStorage.connectivityMethod;
  var region = configuration.cloudStorage.region;
  return JsxRuntime.jsx("h4", {
              children: configuration.cloudStorage.rawStorageCapacity + ((
                  diskType !== undefined ? diskType + ", " : ""
                ) + ((
                    connectivity !== undefined ? connectivity + ", " : ""
                  ) + (
                    region !== undefined ? region : ""
                  ))),
              className: css.panelHeading
            });
}

function UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("p", {
                        children: props.heading,
                        className: css.productName
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                              options: options
                            }),
                        className: css.checkboxes
                      })
                ],
                className: css.checkboxesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptionsWithHeading = {
  make: UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading
};

function UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var configuration = props.configuration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var notes = configuration.additionalRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: panelHeading(configuration),
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ServerInstance.make, {
                            configuration: configuration.cloudStorage,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength
                          }),
                      JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Features",
                            options: configuration.features
                          }),
                      JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Access Type",
                            options: configuration.accessTypes
                          }),
                      JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Certifications",
                            options: configuration.certifications
                          }),
                      notes !== undefined ? JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Custom Note or Requirements",
                                      className: css.productName
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: notes,
                                      className: css.additionalNotes
                                    })
                              ],
                              className: css.propertiesContainer
                            }) : null
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptionsWithHeading ,
  make ,
}
/* css Not a pure module */
