// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserCloudIaasStorageProject_Details_RequiredProductsScss from "./UserCloudIaasStorageProject_Details_RequiredProducts.scss";
import * as UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription from "./UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserCloudIaasStorageProject_Details_RequiredProductsScss;

function getContractLengthText(contractLength) {
  var years = contractLength / 12 | 0;
  return String(years) + (
          years > 1 ? " years" : " year"
        );
}

function UserCloudIaasStorageProject_Details_RequiredProducts_ServerInstance(props) {
  var contractLength = props.contractLength;
  var startDate = props.startDate;
  var configuration = props.configuration;
  var diskType = configuration.diskType;
  var connectivity = configuration.connectivityMethod;
  var transferOut = configuration.transferOut;
  var match = configuration.getRequestsCount;
  var match$1 = configuration.getRequestsUnit;
  var match$2 = configuration.putRequestsCount;
  var match$3 = configuration.putRequestsUnit;
  var region = configuration.region;
  var description = configuration.description;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Server Instances",
                      className: css.productName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Raw Storage Capacity",
                                    value: configuration.rawStorageCapacity
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Disk Type",
                                    value: diskType !== undefined ? diskType : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Connectivity Method",
                                    value: connectivity !== undefined ? connectivity : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Transfer Out",
                                    value: transferOut !== undefined ? String(transferOut) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.stayLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Get Requests",
                                    value: match !== undefined && match$1 !== undefined ? String(match) + (" " + match$1) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Put Requests",
                                    value: match$2 !== undefined && match$3 !== undefined ? String(match$2) + (" " + match$3) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Region",
                                    value: region !== undefined ? region : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Type",
                                    value: configuration.types
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.stayLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Start Date",
                                    value: startDate !== undefined ? $$Date.Naive.format(Caml_option.valFromOption(startDate), "dd MMM yyyy") : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Reservation Term",
                                    value: contractLength !== undefined ? getContractLengthText(contractLength) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Description",
                                    value: description !== undefined ? description : ""
                                  }),
                              className: Cx.cx([
                                    css.description,
                                    css.switchLight
                                  ])
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ]
            });
}

var make = UserCloudIaasStorageProject_Details_RequiredProducts_ServerInstance;

export {
  css ,
  getContractLengthText ,
  make ,
}
/* css Not a pure module */
